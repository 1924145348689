/*-----Chat Box------*/
.chat {
  margin-left: auto;
  margin-right: auto;
  width: 70%;

  .chat-container {
    margin-bottom: 20px;
    border-radius: 0.375rem;
    background-color: #ffffff;
    padding: 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;   /*Make the chat scrollable*/
    max-height: calc(100vh - 6rem);

    .message-container {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

/*-----Chat Message Bubble Styles------*/
.message {
  margin-bottom: 8px;
}

.user {
  align-self: flex-end;
}

.bot {
  align-self: flex-start;
}

.message-text {
  font-size: 14px;
  padding: 1px 15px 15px 15px;
  border-radius: 0.375rem;
}

.bot .message-text {
  background-color: #f3f4f6;
  color: #374151;
}

.user .message-text {
  background-color: #d1edfe;
  color: #1e40af;
}

.source-list {
  margin-top: 8px;
}

.source-text {
  font-size: 14px;
  color: #4b5563;
}

.source-link {
  font-size: 14px;
  color: #4b5563;
  text-decoration: underline;
}

/*-----Footer / Input Box------*/
.footer {
  border-radius: 0.375rem;
  width: 70%;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 10px;
  display: flex;
  flex-direction: column;

  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    #input {
      width: 92%;
    }

    #send-button {
      margin-bottom: 5px;
    }
  }

  .disclaimer{
    font-size: 13px;
    color: grey;
    align-self: center;
  }
}

/*-----HTML Table Styles------*/
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black !important
}

th, td {
  border: 1px solid black;
}

/*-----Personality Icon-----*/
.icon-img{
  padding: 10px;
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.personality-container {
  position: relative; /* This is required for z-index to work */
  z-index: 9999;
  position: fixed;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.7);
  color: #374151;
  right: 10px;
  top: 90px;
  padding: 10px;
}

.buttons-container {
  max-height: 80vh;
  overflow-y: auto;
}

.buttons-container > button {
  display: flex;
  opacity: 100%;
  padding: 10px;
  background-color: #c3c3c5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.buttons-container .left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 80px;
}

.buttons-container .right {
  align-self: center;
  text-align: left;
}

.buttons-container > button:hover {
  background-color: #ffc1078a;
}

.buttons-container > button.active{
  background-color: #ffc107;
}

.sources-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.sources-container > button {
  opacity: 100%;
  background-color: white;
  margin: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 10px;
}

.title {
  font-size: 15px;
  text-align: left;
  color: blue;
}

.author {
  font-weight: 350;
  font-size: 12px;
  color: #006621
}

.pdf {
  justify-self: flex-end;
  align-self: flex-end;
  color: blue;
}

a:hover {
  text-decoration: underline;
}

#side-bar {
  width: 100px;
  margin-right: 10;
  align-self: flex-end;
  opacity: 100%;
}

#side-bar:hover {
  background-color: #f3f4f6;
}