.container {
  width: 1000px;
  margin-top: 25pt;
}

.heading1 {
  font-size: 25pt;
  margin-top: 25pt;
  font-weight: bold;
  color:  #0084c1;
}

.heading2 {
  font-size: 20pt;
  margin-top: 10pt;
  font-weight: bold;
  color: #ffc107;
}

p {
  font-size: 12pt;
  margin-top: 10pt;
}

.individual-p {
  height: auto;
}

.whiteSpace {
  margin-bottom: 60pt;
}

.overview {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0.375rem;
  background-color: #ffffff;
  padding: 24px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.flex-container {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10pt;
  border-radius: 15px;
  background-color: #F3F4F6;
}

.flex-img {
  width: 25%;
  float: left;
  padding: 10px;
}

.flex-text {
  width: 75%;
  float: left;
  padding: 15px;
}

img {
  border-radius: 15px;
}

button {
  margin-top: 5pt;
  padding: 5pt;
  border-radius: 0.375rem;
  background-color: #BEC1C6;
  opacity: 0.5;
  font-size: 8pt;
}

button:hover {
  opacity: 1;
}