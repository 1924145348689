.app {
  text-align: left; /* Center align the text content of the App component */
}

.page{
  width: 100%;
  max-height: calc(100vh - 5.5rem);
  margin-top: 5.5rem;
  /*display: flex;*/
  /*flex-direction: row;*/
}